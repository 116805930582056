/* eslint-disable */
import { Hub } from "@aws-amplify/core"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link } from "gatsby"
import { GridBox, Text } from "@thedgbrt/logology-ui-components"
import { verifyUser } from "../../../state/actions/user"
import Loading from "../../common/Loading"

// setup all amplify modules for all private routes
import Amplify from "@aws-amplify/core"
import Auth from "@aws-amplify/auth"
import { trackAmplitude } from "../../../helpers/amplitude"
import { trackSegmentEvent } from "../../../helpers/segment"
import awsconfig from "../../../aws-exports"
Amplify.configure(awsconfig)

const SigninRedirect = ({ verifyUser }) => {
  const [error, setError] = useState(false)

  useEffect(() => {
    // reset google referrer to avoid "accounts.google.com" referral link
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("set", { referrer: "https://www.logology.co" })
      window.gtag("event", "page_view")
    }

    Hub.listen("auth", authListener)

    // in case a logged in user lands on this page, we redirect them
    verifyUser(Auth)
      .then(user => {
        if (!user) throw new Error("missing user")
        window.location.href = "/myaccount/proposals/"
        return
      })
      .catch(e => {
        // do nothing
      })

    // in case a non logged-in user comes to this page
    // we wait for Hub check and then show an error
    let timer = setTimeout(() => setError(true), 8000)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  const authListener = async data => {
    switch (data.payload.event) {
      case "signIn":
        removeAuthListener()
        trackSegmentEvent("track", "Signin", { type: "google_auth" })
        trackAmplitude("Signin", { type: "google auth" })

        if (typeof window !== "undefined") {
          // don't know why navigate has issues here
          // it does redirect but doesn't change the url in the browser bar
          window.location.href = "/myaccount/proposals/"
        }
        break
      case "signIn_failure":
        console.log("the error is", data)
        setError(true)
        break
      default:
        console.log("other event " + data.payload)
        break
    }
  }

  const removeAuthListener = () => {
    Hub.remove("auth", authListener)
  }

  if (error === true)
    return (
      <GridBox type={3} colSpans={[[2, 5], [2, 5], [3, 6]]} withBgContainer>
        <Text standalone>
          There was an error redirecting you.{" "}
          <Link to="/signin/">Try logging in again.</Link>
        </Text>
      </GridBox>
    )

  return <Loading />
}

export default connect(
  null,
  { verifyUser }
)(SigninRedirect)
